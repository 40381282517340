import Menu from './Menu';
import MunicipalitySelector from './MunicipalitySelector';
import SupportStatementForm from './SupportStatementForm';

// The 'namespace' for custom objects
window.LP = {
    ajaxUrl: '/wp-admin/admin-ajax.php',
};

LP.menu = new Menu();
LP.menu.enableSticky();

LP.municipalitySelector = new MunicipalitySelector();
LP.municipalitySelector.enableSelecting();

LP.supportStatementForm = new SupportStatementForm();
LP.supportStatementForm.enableSubmit();
