export default function() {
    /**
     * The select element to choose the municipality with
     * @type {HTMLElement}
     */
    const selectorElement = document.getElementById('municipality-selector');

    /**
     * All the information blocks
     * @type {HTMLCollectionOf<Element>}
     */
    const allMunicipalityInfos = document.getElementsByClassName('municipality-info');

    /**
     * The element containing the submit part of the form
     * @type {HTMLElement}
     */
    const submitWrapper = document.getElementById('municipality-form-submit');

    /**
     * Enable the municipality selector
     * @return {void}
     */
    this.enableSelecting = () => {
        // The selector needs to exist on the page
        if (selectorElement === null) {
            return;
        }

        selectorElement.addEventListener('change', event => {
            // Hide all the elements
            for (let element of allMunicipalityInfos) {
                element.classList.add('d-none');
            }

            // Don't show any information, when the placeholder option is selected
            if (selectorElement.value === '') {
                // Hide the submit part of the form
                submitWrapper.classList.add('d-none');

                return;
            }

            // Show the selected info
            const municipalityInfoId = `municipality-info-${selectorElement.value}`;
            document.getElementById(municipalityInfoId).classList.remove('d-none');

            // Show the submit part of the form
            submitWrapper.classList.remove('d-none');
        });
    };
}
