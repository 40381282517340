export default function()
{
    /**
     * The parent element of the main menu
     * @type {HTMLElement}
     */
    const mainMenuWrapper = document.getElementById('main-menu-wrapper');

    /**
     * The amount of pixels scrolled, after which the CSS should be applied
     * @type {Number}
     */
    const breakpoint = 90;

    /**
     * Indicates if the page has been scrolled past the breakpoint
     * @type {Boolean}
     */
    let pastBreakpoint = false;

    /**
     * Indicates if the CSS is applied or not
     * @type {Boolean}
     */
    let cssApplied = false;

    /**
     * Enable the fixed position of the menu
     * @return {void}
     */
    this.enableSticky = () => {
        // The callback that enable the CSS toggling
        const callback = () => {
            pastBreakpoint = (window.scrollY >= breakpoint);

            if (pastBreakpoint && ! cssApplied) {
                mainMenuWrapper.classList.add('fixed');
                cssApplied = true;
            } else if(! pastBreakpoint && cssApplied) {
                mainMenuWrapper.classList.remove('fixed');
                cssApplied = false;
            }
        };

        window.addEventListener('load', callback);
        window.addEventListener('scroll', callback);
    };
}
