export default function() {
    /**
     * The submit button of the form
     * @type {HTMLElement}
     */
    const submitButton = document.getElementById('municipality-form-button');

    /**
     * Element showing normal submit button text
     * @type {HTMLElement}
     */
    const submitButtonNormalTextElement = document.getElementById('municipality-form-button-normal-text');

    /**
     * Element showing text for form processing
     * @type {HTMLElement}
     */
    const submitButtonProcessingTextElement = document.getElementById('municipality-form-button-processing-text');

    /**
     * The element that shows success/error messages
     * @type {HTMLElement}
     */
    const messageElement = document.getElementById('municipality-form-response');

    /**
     * Indicates whether the request is currently processing
     * @type {boolean}
     */
    let processing = false;

    /**
     * Enable the support statement form
     * @return {void}
     */
    this.enableSubmit = () => {
        // The submit element is required
        if (submitButton === null) {
            return;
        }

        // Enable submitting
        submitButton.addEventListener('click', event => {
            event.preventDefault();

            // Prevent duplicate requests
            if (processing === true) {
                return;
            }

            processing = true;
            showProcessingButtonText();
            hideMessage();

            const request = new XMLHttpRequest();
            request.open('POST', LP.ajaxUrl, true);

            request.onload = function() {
                const response = JSON.parse(this.response);
                if (response.status && response.message) {
                    showMessage(response.status, response.message);
                }

                console.log(response, response.status, response.message);
                processing = false;
                showNormalButtonText();
            };

            request.onerror = function() {
                showMessage('error', 'Er ging iets mis, probeer het nog eens');
                processing = false;
                showNormalButtonText();
            };

            // Get form elements
            const municipalityField = document.getElementById('municipality-selector');
            const emailField = document.getElementById('municipality-form-input-email');

            // Create POST data
            const formData = new FormData();
            formData.append('action', 'send_support_statement_email');
            formData.append('municipality_number', municipalityField.value);
            formData.append('email', emailField.value);

            request.send(formData);
        });
    };

    /**
     * @return {void}
     */
    const showNormalButtonText = () => {
        submitButton.classList.remove('processing');
        submitButtonProcessingTextElement.classList.add('d-none');
        submitButtonNormalTextElement.classList.remove('d-none');
    };

    /**
     * @return {void}
     */
    const showProcessingButtonText = () => {
        submitButton.classList.add('processing');
        submitButtonNormalTextElement.classList.add('d-none');
        submitButtonProcessingTextElement.classList.remove('d-none');
    };

    /**
     * @return {void}
     */
    const hideMessage = () => {
        messageElement.innerText = '';
        messageElement.classList.add('d-none');
    };

    /**
     * Show a message with a status
     * @param {string} status
     * @param {string} message
     * @return {void}
     */
    const showMessage = (status, message) => {
        if (status === 'ok') {
            messageElement.classList.remove('error');
            messageElement.classList.add('success');
        } else {
            messageElement.classList.remove('success');
            messageElement.classList.add('error');
        }

        messageElement.classList.remove('d-none');
        messageElement.innerText = message;
    };
}
